import axios from 'axios';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningIcon from '@mui/icons-material/Warning';
import {
	Elements,
	useStripe,
	useElements,
	PaymentElement,
	// PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { useLoadScript } from '@react-google-maps/api';
import {
	AppBar,
	Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	MobileStepper,
	OutlinedInput,
	Select,
	TextField,
	Typography,
	CircularProgress,
	Link,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
	LocalizationProvider,
	DatePicker,
	TimePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import '@mui/lab/themeAugmentation';
import './App.css';
import oneTier from './assets/one-tier.png';
import twoTier from './assets/two-tier.png';
import heartShaped from './assets/heart-shaped.png';
import cakeMania from './assets/cake-mania.svg';
import queryString from 'query-string'; // optional, for easier parsing

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const steps = [
	'Message',
	'Theme',
	'Look',
	'Size',
	'Flavour',
	'Sponge',
	'Allergy',
	'Schedule',
	'Personal',
	'Address',
	'Checkout',
];

function App() {
	return (
		<Container>
			<AppBar position="static" color="transparent" elevation={0}>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					padding="16px"
				>
					<img src={cakeMania} alt="Cake Mania" />
				</Box>
				<Router>
					<div className="app-container">
						<Routes>
							{/* <Route path="/:account" element={<Checkout />} />
              <Route path="/create-pay-link" element={<CreateLink />} /> */}
							<Route
								path="/success"
								element={<OrderConfirmation />}
							/>
							<Route path="/" element={<CakeMania />} />
						</Routes>
					</div>
				</Router>
			</AppBar>
		</Container>
	);
}

const OrderConfirmation = ({ paymentIntentId }) => {
	const location = useLocation();

	const queryParams = queryString.parse(location.search);

	const paymentIntent = paymentIntentId ?? queryParams?.payment_intent;

	console.log('paymentIntent', paymentIntent);

	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(null);
	const [paymentDetails, setPaymentDetails] = useState(null);

	useEffect(() => {
		console.log(
			`${process.env.REACT_APP_API_URL}/restaurant/verify-cake-mania-order?payment_intent=${paymentIntent}`
		);

		const fetchPaymentDetails = async (attempt = 1) => {
			try {
				const response = await axios({
					method: 'get',
					url: `${process.env.REACT_APP_API_URL}/restaurant/verify-cake-mania-order?payment_intent=${paymentIntent}`,
				});
				const data = response?.data?.body ?? {};

				// Check payment status
				// data.status = "processing";
				setStatus(data.status);
				setPaymentDetails(data);
				if (data.status === 'processing') {
					if (attempt <= 3) {
						setTimeout(() => {
							fetchPaymentDetails(attempt + 1);
						}, 2000);
					} else {
						setStatus('unexpected');
					}
				}
			} catch (err) {
				setStatus('error');
			} finally {
				setLoading(false);
			}
		};

		if (paymentIntent) {
			fetchPaymentDetails();
		}
	}, [paymentIntent]);

	if (loading) {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height="50vh"
				textAlign="center"
			>
				<CircularProgress />
				<Typography variant="h6" mt={2}>
					Processing payment
				</Typography>
			</Box>
		);
	}

	switch (status) {
		case 'succeeded':
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="70vh"
					textAlign="center"
					//   bgcolor="#f5f5f5"
				>
					<ThumbUpIcon color="primary" style={{ fontSize: 100 }} />
					<Typography variant="h4" gutterBottom>
						Thank you for the order
					</Typography>
					<Typography variant="body1" gutterBottom>
						Your order has been confirmed, approx time of arrival
					</Typography>
					<Box display="flex" alignItems="center" mb={2}>
						<AccessTimeIcon style={{ marginRight: 8 }} />
						<Typography variant="body1">
							{paymentDetails?.deliveryTime || 'N/A'}
						</Typography>
					</Box>
					<Typography variant="body1">
						Your Order number is
					</Typography>
					<Typography variant="h6" color="primary" gutterBottom>
						# {paymentDetails?.orderNumber || 'N/A'}
					</Typography>
					<Link href="/" variant="body1">
						Go to Home
					</Link>
				</Box>
			);
		case 'canceled':
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="50vh"
					textAlign="center"
				>
					<ErrorIcon style={{ fontSize: 100, color: 'red' }} />
					<Typography variant="h4" gutterBottom>
						Payment Canceled
					</Typography>
					<Typography variant="body1" gutterBottom>
						Your payment was canceled. Please try again.
					</Typography>
					<Link href="/" variant="body1">
						Go to Home
					</Link>
				</Box>
			);
		case 'unexpected':
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="50vh"
					textAlign="center"
				>
					<WarningIcon style={{ fontSize: 100, color: 'orange' }} />
					<Typography variant="h4" gutterBottom>
						Unexpected Status
					</Typography>
					<Typography variant="body1" gutterBottom>
						An unexpected issue occurred while processing your
						payment!
					</Typography>
					<Link href="/" variant="body1">
						Go to Home
					</Link>
				</Box>
			);
		case 'processing':
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="50vh"
					textAlign="center"
				>
					<CircularProgress />
					<Typography variant="h6" mt={2}>
						Payment is being processed
					</Typography>
				</Box>
			);
		case 'requires_action':
		case 'requires_capture':
		case 'requires_confirmation':
		case 'requires_payment_method':
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="50vh"
					textAlign="center"
				>
					<WarningIcon style={{ fontSize: 100, color: 'orange' }} />
					<Typography variant="h4" gutterBottom>
						Additional Action Required
					</Typography>
					<Typography variant="body1" gutterBottom>
						Your payment requires additional actions. Please follow
						the instructions.
					</Typography>
					<Link href="/" variant="body1">
						Go to Home
					</Link>
				</Box>
			);
		case 'error':
		default:
			return (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					height="50vh"
					textAlign="center"
				>
					<ErrorIcon style={{ fontSize: 100, color: 'red' }} />
					<Typography variant="h4" gutterBottom>
						Payment Failed
					</Typography>
					<Typography variant="body1" gutterBottom>
						There was an issue with your payment. Please try again
						later.
					</Typography>
					<Link href="/" variant="body1">
						Go to Home
					</Link>
				</Box>
			);
	}
};

function CakeMania() {
	console.log('App called');

	const [activeStep, setActiveStep] = useState(0);
	const [constants, setConstants] = useState({
		isLoaded: false,
		error: '',
		items: [],
		themes: [],
		looks: [],
		sizes: [],
		flavours: [],
		sponges: [],
		countryCodes: [],
	});

	const [formValues, setFormValues] = useState({
		message: '',
		theme: '',
		customThemeImage: null,
		customThemeText: '',
		look: '',
		size: '',
		serve: 0,
		flavour: '',
		sponge: '',
		notes: '',
		customerName: '',
		countryCode: '',
		phoneNumber: '',
		email: '',
		validEmail: false,
		address: '',
		city: '',
		postalCode: '',
		streetNumber: '',
		apartmentNumber: '',
		country: '',
		latitude: '',
		longitude: '',
		selectedDate: null,
		selectedTime: null,
		formattedDate: '',
		formattedTime: '',
		price: 0,
		currencySymbol: '£',
		paymentIntentId: '',
		clientSecret: '',
		transactionId: '',
		isSubmitting: false,
		navigation: true,
		paymentErrorMsg: '',
		paymentSuccessMsg: '',
		paymentSucceed: false,
	});

	const loadData = useCallback(() => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/restaurant/cake-mania-items`,
		}) // Replace with your API key
			.then((res) => {
				const data = res?.data?.body ?? {};
				console.log(data);
				const items = data?.items ?? [];

				const looks = [];

				items.forEach((item) => {
					const { id, type, look, image } = item;
					if (!looks.some((unique) => unique.type === type)) {
						looks.push({ id, type, look, image });
					}
				});

				let sizes = filterSizes(items, looks?.[0]?.type);

				const themes = [...(data?.themes ?? []), 'Custom'];

				const flavours = data?.fillings ?? [];

				const sponges = data?.sponge ?? [];

				const countryCodes = data?.countryCodes ?? [];

				setConstants({
					...constants,
					isLoaded: true,
					error: '',
					items: items,
					themes: themes,
					looks: looks,
					sizes: sizes,
					flavours: flavours,
					sponges: sponges,
					countryCodes: countryCodes,
				});

				setFormValues({
					...formValues,
					id: looks?.[0]?.id,
					look: looks?.[0]?.look,
					type: looks?.[0]?.type,
					size: sizes?.[0]?.size,
					serve: sizes?.[0]?.serve,
					// theme: themes?.[0],
					// flavour: flavours?.[0],
					// sponge: sponges?.[0],
					// countryCode: countryCodes?.[0],
				});
			})
			.catch((error) => {
				console.error('Error loading Google Maps script:', error);
				setConstants({
					...constants,
					isLoaded: true,
					error:
						error?.response?.data?.message ??
						error?.message ??
						'Please try again',
				});
			});
	}, [constants, setConstants, formValues, setFormValues]);

	useEffect(() => {
		if (!constants.isLoaded) {
			loadData();
		}
	}, [constants, loadData]);

	const handleNext = () => {
		console.log('handleNext : ', activeStep < steps.length - 2);
		if (activeStep < steps.length - 2) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			submitForm()
				.then((_) => {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				})
				.catch((err) => {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				});
		}
	};

	const retryLoadData = () => {
		setConstants({ ...constants, error: '', isLoaded: false });
		loadData();
	};

	const reSubmitForm = () => {
		submitForm()
			.then((_) => {})
			.catch((err) => {});
	};

	const submitForm = () => {
		return new Promise((resolve, reject) => {
			setFormValues({ ...formValues, isSubmitting: true, error: '' });
			console.log('data : ', formValues);

			const formData = new FormData();
			formData.append('id', formValues.id);
			formData.append('message', formValues.message ?? '');
			formData.append('theme', formValues.theme ?? '');
			formData.append('custom_theme', formValues.customThemeText ?? '');
			formData.append('flavour', formValues.flavour ?? '');
			formData.append('sponge', formValues.sponge ?? '');
			formData.append('notes', formValues.notes ?? '');
			formData.append('schedule_date', formValues.formattedDate ?? '');
			formData.append('schedule_time', formValues.formattedTime ?? '');
			formData.append('email', formValues.email ?? '');
			formData.append('name', formValues.customerName ?? '');
			formData.append('country_code', formValues.countryCode ?? '');
			formData.append('phone', formValues.phoneNumber);
			formData.append('latitude', formValues.latitude ?? '');
			formData.append('longitude', formValues.longitude ?? '');
			formData.append('address', formValues.address ?? '');
			formData.append('city', formValues.city ?? '');
			formData.append('country', formValues.country ?? '');
			formData.append('postal_code', formValues.postalCode ?? '');
			formData.append('street_number', formValues.streetNumber ?? '');
			formData.append(
				'apartment_number',
				formValues.apartmentNumber ?? ''
			);

			// Append the image file
			if (formValues.customThemeImage) {
				formData.append('image', formValues.customThemeImage); // 'image' is the key for the image file
			}

			axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_URL}/restaurant/cake-mania-order`,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				data: formData,
			}) // Replace with your API key
				.then((response) => {
					const price = response?.data?.body?.price;
					const paymentIntentId =
						response?.data?.body?.payment_intent_id;
					const clientSecret = response?.data?.body?.client_secret;
					const transactionId = response?.data?.body?.transaction_id;
					const currencySymbol =
						response?.data?.body?.currency_symbol;
					if (
						!price ||
						!paymentIntentId ||
						!clientSecret ||
						!transactionId
					) {
						setFormValues({
							...formValues,
							error: 'Failed to get payment details',
							isSubmitting: false,
						});
					} else {
						setFormValues({
							...formValues,
							price: price,
							currencySymbol: currencySymbol,
							paymentIntentId: paymentIntentId,
							clientSecret: clientSecret,
							transactionId: transactionId,
							error: '',
							isSubmitting: false,
						});
					}
					console.log(response?.data);
					resolve();
				})
				.catch((error) => {
					setFormValues({
						...formValues,
						error:
							error?.response?.data?.message ??
							error?.message ??
							'Please try again',
						isSubmitting: false,
					});
					reject(error);
				})
				.finally(resolve);
		});
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === 'email') {
			validateEmail(value);
		} else {
			setFormValues({ ...formValues, [name]: value });
		}
	};

	const validateEmail = (value) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(value)) {
			console.log('invalid email');
			setFormValues({ ...formValues, email: value, validEmail: false });
		} else {
			console.log('valid email');
			setFormValues({ ...formValues, email: value, validEmail: true });
		}
	};

	const handleFileChange = (e) => {
		if(e?.target?.files[0]) {
			setFormValues({
				...formValues,
				customThemeImage: e.target.files[0],
			});
		}
	};

	const handleLookSelection = ({ id, type, look }) => {
		console.log('handleLookSelection : ', { id, type, look });
		let sizes = filterSizes(constants?.items, type);

		setConstants({
			...constants,
			sizes: sizes,
		});

		setFormValues({
			...formValues,
			look,
			type,
			id: sizes?.[0]?.id,
			size: sizes?.[0]?.size,
			serve: sizes?.[0]?.serve,
		});
	};

	const handleSizeSelection = ({ id, size, serve }) => {
		console.log('handleSizeSelection : ', { id, size, serve });
		setFormValues({ ...formValues, size, serve, id });
	};

	const handlePhoneNumberChange = (event) => {
		const phoneNumber = event.target.value;
		if (/^\d*$/.test(phoneNumber)) {
			setFormValues({ ...formValues, phoneNumber });
		}
	};

	const renderStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<MessageCompoment
						formValues={formValues}
						handleInputChange={handleInputChange}
					/>
				);
			case 1:
				return (
					<ThemeComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
						handleFileChange={handleFileChange}
						themes={constants.themes}
					/>
				);
			case 2:
				return (
					<LookComponent
						formValues={formValues}
						handleLookSelection={handleLookSelection}
						looks={constants.looks}
					/>
				);
			case 3:
				return (
					<SizeComponent
						formValues={formValues}
						handleSizeSelection={handleSizeSelection}
						sizes={constants.sizes}
					/>
				);
			case 4:
				return (
					<FlavourComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
						flavours={constants.flavours}
					/>
				);
			case 5:
				return (
					<SpongeComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
						sponges={constants.sponges}
					/>
				);
			case 6:
				return (
					<NotesComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
					/>
				);
			case 7:
				// return ScheduleComponent({ formValues, setFormValues });
				return (
					// null
					<ScheduleComponent
						formValues={formValues}
						setFormValues={setFormValues}
					/>
				);
			case 8:
				return (
					<ContactInfoComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
						handlePhoneNumberChange={handlePhoneNumberChange}
						countryCodes={constants.countryCodes}
					/>
				);
			case 9:
				return (
					<AddressComponent
						formValues={formValues}
						handleInputChange={handleInputChange}
						setFormValues={setFormValues}
					/>
				);

			case 10:
				return (
					<CheckoutComponent
						formValues={formValues}
						setFormValues={setFormValues}
						reSubmitForm={reSubmitForm}
					/>
				);
			default:
				return 'Unknown step';
		}
	};

	if (!constants.isLoaded) {
		return <Loader />;
	}

	if (formValues.paymentSucceed) {
		return (
			<OrderConfirmation paymentIntentId={formValues.paymentIntentId} />
		);
	}

	if (constants.error) {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				p={5}
				height="50vh" // Full height of the viewport
				// sx={{ backgroundColor: "rgba(255, 0, 0, 0.1)", borderRadius: 1 }}
			>
				<Typography variant="h6" color="error" gutterBottom>
					{constants.error}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={retryLoadData}
				>
					Retry
				</Button>
			</Box>
		);
	}

	return (
		<StepperFormComponent
			activeStep={activeStep}
			formValues={formValues}
			renderStepContent={renderStepContent}
			handleNext={handleNext}
			handleBack={handleBack}
		/>
	);
}

const StepperFormComponent = ({
	activeStep,
	formValues,
	renderStepContent,
	handleNext,
	handleBack,
}) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<MobileStepper
				variant="dots"
				steps={steps.length}
				position="static"
				activeStep={activeStep}
				sx={{ width: '100%', mt: 4 }}
				nextButton={
					<Button
						size="small"
						onClick={handleNext}
						disabled={
							!formValues.navigation ||
							activeStep === steps.length - 1 ||
							formValues.isSubmitting ||
							(activeStep === 0 && !formValues.message) ||
							(activeStep === 1 &&
								(!formValues.theme ||
									(formValues.theme === 'Custom' &&
										(!formValues.customThemeImage ||
											!formValues.customThemeText)))) ||
							(activeStep === 2 && !formValues.look) ||
							(activeStep === 3 && !formValues.size) ||
							(activeStep === 4 && !formValues.flavour) ||
							(activeStep === 5 && !formValues.sponge) ||
							(activeStep === 7 &&
								(!formValues.formattedDate ||
									!formValues.formattedTime)) ||
							(activeStep === 8 &&
								(!formValues.customerName ||
									!formValues.countryCode ||
									formValues.phoneNumber?.length !== 10 ||
									!formValues.email ||
									!formValues.validEmail)) ||
							(activeStep === 9 &&
								(!formValues.address ||
									!formValues.city ||
									!formValues.country ||
									!formValues.postalCode ||
									!formValues.streetNumber))
						}
					>
						Next
						<KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						size="small"
						onClick={handleBack}
						disabled={
							!formValues.navigation ||
							activeStep === 0 ||
							formValues.isSubmitting
						}
					>
						<KeyboardArrowLeft />
						Back
					</Button>
				}
			/>
			<Box width="100%" marginTop="32px">
				{formValues.isSubmitting ? (
					<Loader />
				) : (
					renderStepContent(activeStep)
				)}
			</Box>
		</Box>
	);
};

const MessageCompoment = ({ formValues, handleInputChange }) => {
	return (
		<Box>
			<Typography variant="h6" align="center" mt={2}>
				Create Your Perfect Cake, Delivered to Your Doorstep with
				CakeMania!
			</Typography>
			<TextField
				label="What should your cake say?"
				fullWidth
				margin="normal"
				name="message"
				value={formValues.message}
				onChange={handleInputChange}
			/>
		</Box>
	);
};

const ThemeComponent = ({
	formValues,
	handleInputChange,
	handleFileChange,
	themes,
}) => {
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Choose a theme</Typography>
			</Box>
			<FormControl fullWidth margin="normal">
				<InputLabel>Choose a theme</InputLabel>
				<Select
					name="theme"
					value={formValues.theme}
					onChange={handleInputChange}
					input={
						<OutlinedInput
							id="select-theme"
							label="Choose a theme"
						/>
					}
				>
					{themes.map((theme) => (
						<MenuItem key={theme} value={theme}>
							{theme}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{formValues.theme === 'Custom' && (
				<>
					<Box display="flex" alignItems="center">
						<TextField
							label="Custom Theme Text"
							name="customThemeText"
							value={formValues.customThemeText}
							onChange={handleInputChange}
							margin="normal"
							fullWidth
						/>
						<input
							type="file"
							accept="image/*"
							style={{ display: 'none' }}
							id="upload-button"
							onChange={handleFileChange}
						/>
						<Button
							variant="contained"
							component="label"
							htmlFor="upload-button"
							style={{ margin: '0 10px', minWidth: 100 }}
						>
							Image
						</Button>
					</Box>
					{formValues.customThemeImage ? (
						<Box
							display="flex"
							justifyContent="space-around"
							mt={2}
						>
							<img
								src={URL.createObjectURL(
									formValues.customThemeImage
								)}
								alt="Custom Theme"
								style={{ minHeight: 150, maxWidth: 200 }}
								onClick={() =>
									document
										.getElementById('upload-button')
										.click()
								}
							/>
						</Box>
					) : null}
				</>
			)}
		</>
	);
};

const LookComponent = ({ formValues, handleLookSelection, looks }) => {
	const lookStyles = {
		imageBox: {
			cursor: 'pointer',
			padding: '5px',
			transition: 'all 0.3s ease-in-out', // Add transition effect
			borderRadius: '8px',
		},
		selectedBorder: {
			border: '2px solid #000',
			backgroundColor: '#ebf1ff',
		},
		defaultBorder: {
			border: '1px solid #ccc',
			backgroundColor: 'white',
		},
		image: {
			width: '90px',
			height: '90px',
		},
	};

	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Choose a look</Typography>
			</Box>

			<Box display="flex" justifyContent="space-around" mt={2}>
				{looks.map((look) => (
					<Box
						display="flex"
						justifyContent="space-around"
						key={look.type}
						onClick={() => handleLookSelection(look)}
						style={{
							...lookStyles.imageBox,
							...(formValues.type === look.type
								? lookStyles.selectedBorder
								: lookStyles.defaultBorder),
						}}
					>
						<img
							src={
								look.type === 1
									? oneTier
									: look.type === 2
									? twoTier
									: heartShaped
							}
							alt={look.look} // Fixed the alt attribute
							style={lookStyles.image}
						/>
					</Box>
				))}
			</Box>
		</>
	);
};

const SizeComponent = ({ formValues, handleSizeSelection, sizes }) => {
	const sizeStyles = {
		sizeBox: {
			cursor: 'pointer',
			padding: '15px 15px',
			textAlign: 'center',
			border: '1px solid #ccc',
			borderRadius: '50%', // Keep this inline since it’s specific to the box
			transition: 'all 0.3s ease-in-out', // Add transition effect
			width: '30px', // Set fixed width
			height: '30px', // Set fixed height
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		selectedBorder: {
			color: 'white',
			backgroundColor: 'black',
		},
		defaultBorder: {
			color: 'black',
			backgroundColor: 'white',
		},
	};
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Choose cake size</Typography>
			</Box>
			<Box display="flex" justifyContent="space-around" mt={2}>
				{sizes.map((e) => (
					<Box
						key={e.size}
						onClick={() => handleSizeSelection(e)}
						style={{
							...sizeStyles.sizeBox,
							...(formValues.size === e.size
								? sizeStyles.selectedBorder
								: sizeStyles.defaultBorder),
						}}
					>
						<Typography variant="h5">{e.size}</Typography>
					</Box>
				))}
			</Box>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Serves {formValues.serve}</Typography>
			</Box>
		</>
	);
};

const FlavourComponent = ({ formValues, handleInputChange, flavours }) => {
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Choose a filling/flavour</Typography>
			</Box>
			<FormControl fullWidth margin="normal">
				<InputLabel>Choose a filling/flavour</InputLabel>
				<Select
					name="flavour"
					value={formValues.flavour}
					onChange={handleInputChange}
					input={
						<OutlinedInput
							id="select-flavour"
							label="Choose a filling/flavour"
						/>
					}
				>
					{flavours.map((flavour) => (
						<MenuItem key={flavour} value={flavour}>
							{flavour}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

const SpongeComponent = ({ formValues, handleInputChange, sponges }) => {
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Choose a sponge</Typography>
			</Box>
			<FormControl fullWidth margin="normal">
				<InputLabel>Choose a sponge</InputLabel>
				<Select
					name="sponge"
					value={formValues.sponge}
					onChange={handleInputChange}
					input={
						<OutlinedInput
							id="select-sponge"
							label="Choose a sponge"
						/>
					}
				>
					{sponges.map((sponge) => (
						<MenuItem key={sponge} value={sponge}>
							{sponge}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

const NotesComponent = ({ formValues, handleInputChange }) => {
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Allergies or notes</Typography>
			</Box>
			<TextField
				label="Any allergies or notes?"
				name="notes"
				value={formValues.notes}
				onChange={handleInputChange}
				fullWidth
				margin="normal"
			/>
		</>
	);
};

const ScheduleComponent = ({ formValues, setFormValues }) => {
	const minDate = moment().add(2, 'days');
	const maxDate = moment().add(20, 'days');

	const [date, setDate] = useState(formValues.selectedDate);
	const [time, setTime] = useState(formValues.selectedTime);

	useEffect(() => {
		console.log('useEffect called');
		
		if (date && !moment(date).isSame(moment(formValues.selectedDate))) {
			setFormValues({
				...formValues,
				formattedDate: moment(date).format('DD/MM/YYYY'),
				selectedDate: date,
			});
		}

		if (time && !moment(time).isSame(moment(formValues.selectedTime))) {
			console.log()
			setFormValues({
				...formValues,
				formattedTime: moment(time).format('LT'),
				selectedTime: time,
			});
		}
	}, [date, time, setFormValues, formValues]);

	const handleDateChange = (newDate) => {
		setDate(newDate);
		setTime(null); // Reset time if the date changes
	};

	const handleTimeChange = (newTime) => {
		setTime(newTime);
	};

	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Schedule Delivery</Typography>
			</Box>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
					mt={2}
				>
					<DatePicker
						label="Select Date"
						value={date}
						onChange={handleDateChange}
						renderInput={(params) => (
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									style: { pointerEvents: 'auto' }, // Ensure icons are clickable
								}}
								inputProps={{
									...params.inputProps,
									style: { pointerEvents: 'auto' }, // Ensure icons are clickable
								}}
							/>
						)}
						minDate={minDate}
						maxDate={maxDate}
					/>
					<TimePicker
						label="Select Time"
						value={time}
						onChange={handleTimeChange}
						renderInput={(params) => (
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									style: { pointerEvents: 'auto' }, // Ensure icons are clickable
								}}
								inputProps={{
									...params.inputProps,
									style: { pointerEvents: 'auto' }, // Ensure icons are clickable
								}}
							/>
						)}
						disabled={!date}
					/>
				</Box>
			</LocalizationProvider>
			<Typography variant="caption">
				Order at least 48 hrs in advance
			</Typography>
		</>
	);
};

const ContactInfoComponent = ({
	formValues,
	handleInputChange,
	handlePhoneNumberChange,
	countryCodes,
}) => {
	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Contact Information</Typography>
			</Box>
			<TextField
				label="Customer Name"
				fullWidth
				margin="normal"
				name="customerName"
				value={formValues.customerName}
				onChange={handleInputChange}
			/>
			<Box display="flex" alignItems="center" mt={1}>
				<FormControl variant="outlined" sx={{ minWidth: 100 }}>
					<InputLabel id="country-code-label">Code</InputLabel>
					<Select
						labelId="country-code-label"
						name="countryCode"
						value={formValues.countryCode}
						onChange={handleInputChange}
						label="Code"
					>
						{countryCodes.map(({ country, code }) => (
							<MenuItem key={code} value={code}>
								{code} ({country})
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					label="Phone Number"
					variant="outlined"
					name="phoneNumber"
					value={formValues.phoneNumber}
					onChange={handlePhoneNumberChange}
					inputProps={{ maxLength: 10 }} // Set a max length for the phone number
					fullWidth
					sx={{ marginLeft: 2 }}
				/>
			</Box>
			<TextField
				label="Email"
				type="email"
				fullWidth
				margin="normal"
				name="email"
				value={formValues.email}
				onChange={handleInputChange}
			/>
		</>
	);
};

const AddressComponent = ({ formValues, handleInputChange, setFormValues }) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries: ['places'],
	});

	const autocompleteRef = useRef(null);

	useEffect(() => {
		if (isLoaded) {
			console.log('autocomplete', isLoaded);
			const autocomplete = new window.google.maps.places.Autocomplete(
				autocompleteRef.current,
				{ types: ['address'] }
			);

			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				const addressComponents = place.address_components;
				console.log(JSON.stringify(addressComponents, '\n', '\t'));

				const getComponent = (components, types) => {
					for (let type of types) {
						const component = components.find((component) =>
							component.types.includes(type)
						);
						if (component) return component;
					}
					return {};
				};

				const streetNumber =
					getComponent(addressComponents, ['street_number'])
						.long_name || '';
				const address =
					getComponent(addressComponents, ['route']).long_name || '';
				const city =
					getComponent(addressComponents, [
						'locality',
						'sublocality',
						'postal_town',
						'administrative_area_level_2',
					]).long_name || '';
				const postalCode =
					getComponent(addressComponents, ['postal_code'])
						.long_name || '';
				const country =
					getComponent(addressComponents, ['country']).long_name ||
					'';

				setFormValues({
					...formValues,
					address,
					city,
					postalCode,
					streetNumber,
					country,
					latitude: place.geometry?.location?.lat()?.toString() || '',
					longitude:
						place.geometry?.location?.lng()?.toString() || '',
				});
			});
		}
	}, [isLoaded, formValues, setFormValues]);

	return (
		<>
			<Box display="flex" justifyContent="space-around" mt={2}>
				<Typography variant="h6">Delivery Address</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					marginBottom: 2,
				}}
				mt={2}
			>
				<TextField
					label="Building / Street name"
					name="address"
					value={formValues.address}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
					inputRef={autocompleteRef}
					autoComplete="off"
				/>
				<TextField
					label="House / Street Number"
					name="streetNumber"
					value={formValues.streetNumber}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
				/>
				<TextField
					label="Flat / Unit / Floor (Optional)"
					name="apartmentNumber"
					value={formValues.apartmentNumber}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
				/>
				<TextField
					label="City"
					name="city"
					value={formValues.city}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
				/>
				<TextField
					label="Country"
					name="country"
					value={formValues.country}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
				/>
				<TextField
					label="Postal Code"
					name="postalCode"
					value={formValues.postalCode}
					onChange={handleInputChange}
					fullWidth
					// margin="normal"
				/>
			</Box>
		</>
	);
};

const CheckoutComponent = ({ formValues, reSubmitForm, setFormValues }) => {
	console.log('formValues.currencySymbol : ', formValues.currencySymbol);
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			p={5}
			height="50vh"
			mt={5}
		>
			{formValues.error ? (
				<>
					<Typography variant="h6" color="error" gutterBottom>
						{formValues.error}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={reSubmitForm}
					>
						Retry
					</Button>
				</>
			) : formValues.paymentErrorMsg ? (
				<Typography variant="h6" color="error" gutterBottom>
					{formValues.paymentErrorMsg}
				</Typography>
			) : formValues.paymentSuccessMsg ? (
				<Typography variant="h6" gutterBottom>
					{formValues.paymentSuccessMsg}
				</Typography>
			) : (
				<>
					<Typography variant="h5" gutterBottom>
						Price: {formValues.currencySymbol}
						{formValues.price}
					</Typography>
					<Elements
						stripe={stripePromise}
						options={{ clientSecret: formValues.clientSecret }}
					>
						<CheckoutForm
							formValues={formValues}
							setFormValues={setFormValues}
						/>
					</Elements>
				</>
			)}
		</Box>
	);
};

// <Elements
//   stripe={stripePromise}
//   options={{ clientSecret: formValues.clientSecret }}
// >
//   <PaymentForm
//     setFormValues={setFormValues}
//     formValues={formValues}
//   />
// </Elements>

const CheckoutForm = ({ formValues, setFormValues }) => {
	const stripe = useStripe();
	const elements = useElements();
	// const [paymentRequest, setPaymentRequest] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	// const clientSecret = formValues.clientSecret;

	// useEffect(() => {
	// 	if (!stripe || !clientSecret) {
	// 		return;
	// 	}

	// 	// Setup the Payment Request Button
	// 	const pr = stripe.paymentRequest({
	// 		country: 'GB',
	// 		currency: 'gbp',
	// 		total: {
	// 			label: 'Demo total',
	// 			amount: formValues.price * 100, // Amount in cents
	// 		},
	// 		disableWallets: ['link', 'browserCard'],
	// 	});

	// 	pr.canMakePayment().then((result) => {
	// 		if (result) {
	// 			setPaymentRequest(pr);
	// 		}
	// 	});
	// }, [stripe, clientSecret, formValues]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setFormValues({ ...formValues, navigation: false });
		setLoading(true);
		setError('');

		if (!stripe || !elements) {
			return;
		}

		console.log('process.env: ', JSON.stringify(process.env));

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url:
					process.env.REACT_APP_APP_LINK +
					'/success?transaction_id=' +
					formValues.transactionId,
			},
			redirect: 'if_required',
		});

		setLoading(false);
		if (error) {
			console.error(error);
			setError(error.message);
			setFormValues({ ...formValues, navigation: true });
		} else {
			console.log('Payment succeeded!');
			// Handle successful payment here
			setFormValues({ ...formValues, paymentSucceed: true });
		}
	};

	return (
		<Container maxWidth="sm">
			<Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
				{/* {paymentRequest && (
					<Box sx={{ mb: 2 }}>
						<PaymentRequestButtonElement
							options={{ paymentRequest: paymentRequest }}
						/>
					</Box>
				)} */}
				<Box
					sx={{
						mb: 2,
						border: '1px solid #e0e0e0',
						borderRadius: '4px',
						padding: '16px',
					}}
				>
					<PaymentElement />
				</Box>
				{error && <Box sx={{ color: 'red', mb: 2 }}>{error}</Box>}
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disabled={!stripe || !elements || loading}
					fullWidth
				>
					{loading ? <CircularProgress size={24} /> : 'Pay'}
				</Button>
			</Box>
		</Container>
	);
};

const Loader = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '50vh',
			}}
		>
			<CircularProgress size="90px" />
		</Box>
	);
};

const sortSizes = (sizes) => {
	// Define the actual order
	const order = ['XS', 'S', 'M', 'L', 'XL'];

	// Create a custom sort function
	sizes.sort((a, b) => {
		return order.indexOf(a.size) - order.indexOf(b.size);
	});

	return sizes;
};

const filterSizes = (items, type) => {
	let sizes = items
		?.filter((e) => {
			return e.type === type;
		})
		?.map((e) => ({ size: e.size, serve: e.serve, id: e.id }));

	console.log('filterSizes : ', sizes);

	return sortSizes(sizes);
};

export default App;
